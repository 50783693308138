














import Vue from 'vue';
import { Clock } from './';
export default Vue.extend({
  components: {
    Clock,
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener('scroll', this.showContent);
      this.showContent();
    });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.showContent);
  },
  methods: {
    showContent() {
      const footerContentSection1 = document.querySelector('.footer-content-section-1') as HTMLElement;
      const footerContentSection2 = document.querySelector('.footer-content-section-2') as HTMLElement;
      const footer1 = footerContentSection1.getBoundingClientRect();
      const footer2 = footerContentSection2.getBoundingClientRect();
      if (footer1.top < window.innerHeight - (footer1.height / 2)) {
        footerContentSection1.classList.add('show-content');
      }
      if (footer2.top < window.innerHeight - (footer2.height / 2)) {
        footerContentSection2.classList.add('show-content');
        window.removeEventListener('scroll', this.showContent);
      }
    },
  },
});
