






















import Vue from 'vue';
export default Vue.extend({
    data: function() {
        return {
            mapMarkers: [
                {
                    x: '2479px', // position starting from left of map
                    y: '2560px', // position starting from bottom of map
                    name: 'Four Seasons Hotel Prague',
                    description: 'Your base of operations while in Prague—the centrally located Four Seasons is a gateway to the magical Old Town Prague.',
                    image: '/img/maps/prague/four-seasons-hotel.jpg'
                },
                {
                    x: '2558px', // position starting from left of map
                    y: '2559px', // position starting from bottom of map
                    name: 'Old Town Square',
                    description: 'Prague may have swelled over the centuries to become a bustling metropolis, but its heart has always stayed in the same place. Old Town Square has transformed from the political center of Prague into the jewel of art, culture and commerce that it is today. ',
                    image: '/img/maps/prague/old-town-square.jpg'
                },
                {
                    x: '2569px', // position starting from left of map
                    y: '2540px', // position starting from bottom of map
                    name: 'Astronomical Clock',
                    description: 'The Prague Astronomical Clock, or Prague Orloj, has kept track of time and the Earth’s position since 1410. Arrive on the hour for an intricate display as clockwork figurines mark time exactly as they have for 600 years.',
                    image: '/img/maps/prague/astronomical-clock.jpg'
                },
                {
                    x: '2440px', // position starting from left of map
                    y: '2490px', // position starting from bottom of map
                    name: 'Vltava River',
                    description: 'The gently flowing Vltava River provides serene vistas from across Prague. Experience the famous waterway as most only imagine at the Leaders’ Conference 2022 as you’re treated to a sunset dinner cruise through the heart of the city.',
                    image: '/img/maps/prague/vltava-river.jpg'
                },
                {
                    x: '2450px', // position starting from left of map
                    y: '2532px', // position starting from bottom of map
                    name: 'Charles Bridge',
                    description: 'A true Prague icon, the lively Charles Bridge bustles with artists and musicians who ply their trades on this historic footbridge. Take a stroll over the Vltava River and marvel at the 30 baroque statues perched over the water.',
                    image: '/img/maps/prague/charles-bridge.jpg'
                },
                {
                    x: '2299px', // position starting from left of map
                    y: '2639px', // position starting from bottom of map
                    name: 'Prague Castle',
                    description: 'The world’s largest castle complex, Prague Castle, dazzles more than 1.8 million visitors each year with its gardens, towers and palaces. Watch the Changing of the Guards between visits to museums and churches, tour St. Wenceslas’ Vineyard and step back in time to an era of royalty.',
                    image: ''
                },
                {
                    x: '2340px', // position starting from left of map
                    y: '2652px', // position starting from bottom of map
                    name: 'Golden Lane',
                    description: 'The historic home of Prague’s goldsmiths and, according to local legend, alchemists in search of mystical secrets. Shops and museums, including a home once occupied by esteemed writer Franz Kafka, now line the street.',
                    image: '/img/maps/prague/golden-lane.jpg'
                },
                {
                    x: '2313px', // position starting from left of map
                    y: '2682px', // position starting from bottom of map
                    name: 'Royal Garden',
                    description: 'Habsburg Emperor Ferdinand I commissioned the extravagant Royal Garden in 1540, and the French Renaissance-style oasis has been tended expertly ever since.',
                    image: '/img/maps/prague/royal-garden.jpg'
                },
                {
                    x: '2280px', // position starting from left of map
                    y: '2629px', // position starting from bottom of map
                    name: 'St. Vitus Cathedral',
                    description: 'This gothic cathedral is the largest of the three churches found in Prague Castle, towering above the palace’s courtyards. It is the final resting place of many of Prague’s kings and emperors, as well as marvels of art and architecture from more than 700 years of construction.',
                    image: '/img/maps/prague/st-vitus-cathedral.jpg'
                },
                {
                    x: '2295px', // position starting from left of map
                    y: '2616px', // position starting from bottom of map
                    name: 'Old Royal Palace',
                    description: 'Still used for presidential inaugurations to this day, the Old Royal Palace holds an important place in Czech history. It houses a display copy of the Czech crown; St. Vitus Cathedral holds the original only a short walk away.',
                    image: '/img/maps/prague/old-royal-palace.jpg'
                },
                {
                    x: '2318px', // position starting from left of map
                    y: '2647px', // position starting from bottom of map
                    name: 'St. George\'s Basilica',
                    description: 'The oldest surviving church within Prague Castle, the Basilica of St. George has stood for more than 1000 years. In addition to its traditional role as a shrine, it now displays Bohemian art and serves as a concert hall.',
                    image: ''
                },
                {
                    x: '2193px', // position starting from left of map
                    y: '2587px', // position starting from bottom of map
                    name: 'Hradčany District',
                    description: 'One of Prague’s formerly independent boroughs, The Castle District is bursting with noble estates and quaint homes. Several palaces in the district host art from Prague’s National Gallery, and the quiet streets and parks of the neighborhood make the perfect location for an evening walk.',
                    image: '/img/maps/prague/hradcany-district.jpg'
                },
                {
                    x: '2372px', // position starting from left of map
                    y: '2537px', // position starting from bottom of map
                    name: 'John Lennon Wall',
                    description: 'Pay homage to the late Beatle by visiting to the John Lennon Wall, a permanent memorial decorated in art, lyrics and messages from fans. Add your signature to the wall or take in tributes from artists and musicians.',
                    image: '/img/maps/prague/john-lennon-wall.jpg'
                },
                {
                    x: '2613px', // position starting from left of map
                    y: '2551px', // position starting from bottom of map
                    name: 'U Supa',
                    description: 'U Supa bar (Staroměstský Pivovar U Supa) is the oldest bar in Prague, dating back to the 15th century. Sample local beers in the birthplace of the pilsner, now the most-drank beer worldwide, in a medieval tavern atmosphere.',
                    image: '/img/maps/prague/u-supa-2.jpg'
                },
                {
                    x: '2603px', // position starting from left of map
                    y: '2657px', // position starting from bottom of map
                    name: 'Convent of St. Agnes',
                    description: 'Formerly a church and nunnery founded by princess-turned-nun Saint Agnes of Bohemia, this building was converted into part of the Czech National Gallery in the 1960s. During the Leaders’ Conference, you’ll be welcomed to a gala in its ancient halls.',
                    image: '/img/maps/prague/convent-of-st-agnes-1.JPG'
                },
            ]
        }
    },
  components: {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    showContent(index:number) {
        let indexToShow:number = index;
        if (index >= this.mapMarkers.length) indexToShow = 0;
        if (index < 0) indexToShow = this.mapMarkers.length - 1;
        const markerContent = document.querySelector(`#interactive-map__content-${indexToShow}`);
        const markerPin = document.querySelectorAll('.interactive-map__marker')[indexToShow];
        Array.from(document.querySelectorAll('.is-visible')).forEach((elem) => elem.classList.remove('is-visible'));
        if (markerContent) markerContent.classList.add('is-visible');
        if (markerPin) markerPin.classList.add('is-visible');
    }
  }
});
