














import { Component, Vue } from 'vue-property-decorator';
import {
  Navigation,
  CallToAction,
  Clock,
  About,
  Qualified,
  AssurityFooter,
  PragueMap,
} from '@/2022/components';

@Component({
  components: {
    Navigation,
    CallToAction,
    Clock,
    About,
    Qualified,
    AssurityFooter,
    PragueMap,
  },
})
export default class Home extends Vue {}
