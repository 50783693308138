





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      seconds: 0,
      minutes: 0,
      hours: 0,
    };
  },
  created() {
    this.seconds = new Date().getSeconds();
    this.minutes = new Date().getMinutes() + (this.seconds / 60);
    this.hours = new Date().getHours() + (this.minutes / 60);
    setInterval(() => {
      this.seconds += 1;
      this.minutes += 1 / 60;
      this.hours += 1 / 3600;
    }, 1000);
  },
  methods: {
    setClockHour() {
      return `transform: rotateZ(${this.hours * 360 / 24}deg)`;
    },
    setClockMinute() {
      return `transform: rotateZ(${this.minutes * 360 / 120}deg)`;
    },
    setClockSecond() {
      return `transform: rotateZ(${this.seconds * 360 / 120}deg)`;
    },
  },
});
