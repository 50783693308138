




import Vue from 'vue';
export default Vue.extend({
  inheritAttrs: false,
  props: {
    text: {
      type: String,
      default: 'Learn More',
    },
    align: {
      type: String,
      default: 'center',
    },
    scrollSection: {
      default: null,
      type: String,
    },
  },
  methods: {
    scrollDestination() {
      if (this.scrollSection) {
        const section = document.querySelector(this.scrollSection) as HTMLElement;
        section.scrollIntoView({behavior: 'smooth', block: 'start'});
      }
    },
  },
});
