












import { Component, Vue } from 'vue-property-decorator';
import {
  Navigation,
  CallToAction,
  About,
  Qualified,
  AssurityFooter,
} from '@/2021/components';

@Component({
  components: {
    Navigation,
    CallToAction,
    About,
    Qualified,
    AssurityFooter,
  },
})
export default class Home extends Vue {}
