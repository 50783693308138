










































































import Vue from 'vue';

const OPEN_CLASS = 'is-open';

export default Vue.extend({
  mounted() {
    this.$nextTick(function() {
      window.addEventListener('scroll', this.showContent);
      this.showContent();
      this.toggleImages();

      const circles = Array.from(document.querySelectorAll('.circle-group'));
      circles.forEach((element, i) => {
        const cta = element.querySelectorAll('.circle-group__cta')[0];

        if (cta) {
          cta.addEventListener('click', () => {
            element.querySelectorAll('.circle-group__constraint--accordion')[0].classList.toggle(OPEN_CLASS);
          });
        }
      });
    });

    // kill service workers
    navigator.serviceWorker.getRegistrations().then(registrations => {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.showContent);
  },
  methods: {
    toggleImages() {
      const extraInfoSections = Array.from(document.querySelectorAll('.circle-group__extra-info-con'));

      extraInfoSections.forEach(elem => {
        const IMAGE_CLASS = 'circle-group__extra-info-img';
        const DESC_CLASS = 'circle-group__extra-info-desc';

        const elemImage = elem.querySelectorAll(`.${IMAGE_CLASS}`)[0] as HTMLElement;
        const elemDesc = elem.querySelectorAll(`.${DESC_CLASS}`)[0] as HTMLElement;

        elemImage.addEventListener('click', () => {
          const openImage = document.querySelectorAll(`.${IMAGE_CLASS}.${OPEN_CLASS}`)[0] as HTMLElement;

          if (openImage && !elemImage.classList.contains(OPEN_CLASS)) {
            const openSection = openImage.parentElement || document;
            const openDesc = openSection.querySelectorAll(`.${DESC_CLASS}`)[0] as HTMLElement;

            // close previously opened image if there is one
            openDesc.classList.remove(OPEN_CLASS);
            openImage.classList.remove(OPEN_CLASS);
          }

          // open clicked image
          elemImage.classList.toggle(OPEN_CLASS);
          elemDesc.classList.toggle(OPEN_CLASS);
        });
      });
    },
    showContent() {
      const titleSection = document.querySelector('.about-title-section') as HTMLElement;
      const title = titleSection.getBoundingClientRect();
      if (title.top < window.innerHeight - title.height / 2) {
        titleSection.classList.add('title-show');
      }
      const circles = Array.from(document.querySelectorAll('.circle-group'));

      const fadeInItems = Array.from(document.querySelectorAll('.scroll-fade-in'));

      window.addEventListener('scroll', () => {
        fadeInItems.forEach( (element, i) => {
          const elementRect = element.getBoundingClientRect();
          if (elementRect.top < window.innerHeight * .67) element.classList.add('is-shown');
        })
      });
      

      circles.forEach((element, i) => {
        const circleSelector = element.querySelectorAll('.content-group__title')[0];
        if (circleSelector) {
          const circle = circleSelector.getBoundingClientRect();

          if (circle.top < window.innerHeight - circle.height / 2) {
            element.classList.add('circle-show');
          }
        }
      });

      const lastCircle = circles[circles.length - 1].getBoundingClientRect();
      if (lastCircle.top < window.innerHeight - lastCircle.height / 2) {
        circles.forEach(element => {
          element.classList.add('circle-show');
        });
        window.removeEventListener('scroll', this.showContent);
      }
    }
  }
});
