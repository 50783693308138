












import Vue from 'vue';
import { CallToAction } from './';
export default Vue.extend({
  components: {
    CallToAction,
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener('scroll', this.showContent);
      this.showContent();
    });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.showContent);
  },
  methods: {
    showContent() {
      const qualifiedSection = document.querySelector(
        '.get-qualified',
      ) as HTMLElement;
      const qualified = qualifiedSection.getBoundingClientRect();
      if (qualified.top < window.innerHeight / 2) {
        qualifiedSection.classList.add('show-qualified');
        window.removeEventListener('scroll', this.showContent);
      }
    },
  },
});
